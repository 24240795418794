var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "refusal-btn",
      style: {
        width: 40 + "px",
        height: 40 + "px",
        "margin-right": "10px",
        "margin-left": "-25px",
      },
      on: {
        click: function ($event) {
          $event.stopPropagation()
          $event.preventDefault()
          return _vm.data.click.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "svg",
        {
          staticClass: "icon-btn",
          style: { width: "40px", height: "40px" },
          attrs: {
            viewBox: "0 0 48 48",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg",
          },
        },
        [
          _c("circle", {
            attrs: { cx: "24", cy: "24", r: "24", fill: "#FFF2F7" },
          }),
          _c("path", {
            staticClass: "second-fill",
            attrs: {
              d: "M34.1135 13.884C33.6015 13.372 32.7804 13.372 32.2683 13.884L23.9988 22.1536L15.7292 13.884C15.2172 13.372 14.396 13.372 13.884 13.884C13.372 14.396 13.372 15.2172 13.884 15.7292L22.1536 23.9988L13.884 32.2684C13.372 32.7804 13.372 33.6016 13.884 34.1136C14.1352 34.3647 14.4733 34.5 14.8018 34.5C15.1302 34.5 15.4684 34.3744 15.7195 34.1136L23.9891 25.844L32.2587 34.1136C32.5099 34.3647 32.848 34.5 33.1764 34.5C33.5146 34.5 33.843 34.3744 34.0942 34.1136C34.6062 33.6016 34.6062 32.7804 34.0942 32.2684L25.844 23.9988L34.1135 15.7292C34.6256 15.2172 34.6256 14.396 34.1135 13.884Z",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }